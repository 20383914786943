var MyRequest = (function ($) {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // dependencies ///////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // private properties and methods /////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Globale Einstellungen
     */
    var _config = {};

    /**
     * Führt eine asynchrone HTTP-Anfrage (Ajax) aus.
     *
     * @param url
     * @param data
     * @param success
     * @returns {*}
     */
    var _ajax = function (url, data, success) {
        return $.ajax({
            dataType: "json",
            url: '/' + $('html').attr('lang') + '/rpc/' + url,
            data: data,
            success: success,
            type: "POST"
        });
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // public properties and methods //////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    var request = {};

    /**
     * Setzt bzw. Überschreibt die Konfigurationseinstellungen.
     */
    request.setConfig = function (config) {
        $.extend(true, _config, config);
    };

    /**
     * Gibt die Konfigurationseinstellungen zurück.
     */
    request.getConfig = function () {
        return _config;
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // export public properties and methods ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return request;

}(window.jQuery));
var MyApp = (function ($, MyRequest) {
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// dependencies ///////////////////////////////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	var _request = MyRequest;

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// private properties and methods /////////////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	/**
	 * Globale Einstellungen
	 */
	var _config = {
		'GOOGLE_RECAPTCHA_SITE_KEY': null
	};

	/**
	 * @type {boolean}
	 */
	var _pimcoreEditmode = false;

	/**
	 *
	 */
	var _initNavigation = function () {
		var $navigation = $('nav ul.navbar-nav');
		var $subnavis = $navigation.find('li.childs ul');
		$subnavis.hide();

		$(window).scroll(function () {
			var navbar = $('nav.navbar:not(.burger)');
			var sticky = navbar.offset().top;
			var scrollTop = $(this).scrollTop();

			if (scrollTop >= sticky && scrollTop > 0) {
				navbar.addClass('sticky')
			} else {
				navbar.removeClass('sticky');
			}
		});

		$('button.navbar-toggler').click(function () {
			$('nav').toggleClass('burger');
			$('nav .navbar-collapse').toggleClass('collapse');
		});

		// Menü bei Klick außerhalb schließen
		$('html').click(function (e) {
			if ($navigation.has(e.target).length === 0) {
				$navigation.find('li.childs ul:visible').slideUp(250, function () {
					$(this).parent().removeClass('open');
				});
			}
		});

		$navigation.find('> li.childs > a').click(function (e) {
			e.preventDefault();
			$subnav = $(this).next('ul');

			if ($subnav.is(':visible')) {
				$subnav.slideUp(250, function () {
					$(this).parent().removeClass('open');
				});
			} else {
				$navigation.find('li.childs ul:visible').slideUp(250, function () {
					$(this).parent().removeClass('open');
				});

				$(this).parent().addClass('open');
				$subnav.slideDown(250);
			}

			return false;
		});
	};

	/**
	 *
	 */
	var _initSecureMail = function () {
		$('a.securemail').click(function (e) {
			e.preventDefault();
			$this = $(this);
			window.location.href = "mailto:" + $this.data('name') + '@' + $this.data('domain') + '.' + $this.data('tld');
		});
	};

	/**
	 *
	 */
	var _initLightgallery = function () {
		$(".lightgallery1").lightGallery({
			thumbnail: true,
			share: false,
			autoplay: false,
			autoplayControls: false,
			hash: false,
			zoom: false,
			fullScreen: false,
		});
	};

	/**
	 *
	 */
	var _initSlider = function () {
		var configPimcoreEditmode = _pimcoreEditmode ? {
			lazy: false,
			simulateTouch: false,
			autoplay: false,
			effect: false,
			loop: false,
			keyboard: {
				enabled: false,
				onlyInViewport: false
			}
		} : {};

		var swiperStandard = new Swiper('.swiper-container.standard', $.extend({
			// Disable preloading of all images
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false
			},
			simulateTouch: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false
			},
			effect: 'fade',
			loop: true,
		}, configPimcoreEditmode));

		var swiperMultiple = new Swiper('.swiper-container.multiple-slides', $.extend({
			slidesPerView: 'auto',
			centerInsufficientSlides: true,
			loop: true,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			watchOverflow: true,
			simulateTouch: true,
			spaceBetween: 0,
		}, configPimcoreEditmode));
	};

	/**
	 *
	 */
	var _initDataClickable = function () {
		$('body.peditmode0 [data-clickable]').click(function (e) {
			window.location.href = $(this).find('a[data-clickable-target]').attr('href');
		});

		// Focus the target link to get hover/focus style visible
		$('body.peditmode0 [data-clickable]').hover(function () {
			$(this).find('a[data-clickable-target]').focus();
		}, function () {
			$(this).find('a[data-clickable-target]').blur();
		});

		// Do not lose the focus with a mouse click to not show the normal style
		$('body.peditmode0 [data-clickable]').mousedown(function (e) {
			return false;
		});
	};

	/**
	 *
	 */
	var _initCookieConsent = function () {
		window.cookieconsent.initialise({
			type: 'opt-in',
			revokable: false,
			law: {
				regionalLaw: false
			},
			revokeBtn: '<div></div>',
			location: false,
			secure: true,
			content: {
				message: _config.cookieinfo.message,
				deny: _config.cookieinfo.deny,
				allow: _config.cookieinfo.allow,
				link: _config.cookieinfo.link,
				href: _config.cookieinfo.href
			},
			onStatusChange: function (status, chosenBefore) {
				if (this.options.type === 'opt-in' && this.hasConsented()) {
					//loadGoogleAnalyticsOnConsent();
				}
			}
		});
	};


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// public properties and methods //////////////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	var api = {};

	/**
	 * Setzt bzw. überschreibt die Konfigurationseinstellungen.
	 */
	api.setConfig = function (config) {
		$.extend(true, _config, config);
	};

	/**
	 * Gibt die Konfigurationseinstellungen zurück.
	 */
	api.getConfig = function () {
		return _config;
	};


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// optionally one-time init procedures ////////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


	/**
	 * Initialisiert die Seite
	 */
	$(document).ready(function () {
		_pimcoreEditmode = $('body.peditmode1').length ? true : false;

		_initSecureMail();
		_initNavigation();
		_initSlider();
		_initCookieConsent();
		_initLightgallery();
		/*
		_initForms();
		_initDataClickable();

		$('a.securemail').click(function (e) {
			e.preventDefault();
			$this = $(this);
			window.location.href = "mailto:" + $this.data('name') + '@' + $this.data('domain') + '.' + $this.data('tld');
		});

		$('#calltoaction-wrapper a.toggle').click(function (e) {
			e.preventDefault();
			$this = $(this);
			$this.closest('#calltoaction-wrapper').toggleClass('show');
		});
		*/
	});


	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// export public properties and methods ///////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return api;

}(window.jQuery, MyRequest));


